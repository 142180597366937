<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create industrial properties')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.industrial-property.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="properties"
              :sort-mapping="sortMapping"
              :title="'industrial properties'"
              :total="propertiesTotal"
              :fetch-path="'tecnologyTransfers/fetchIndustrialProperty'"
              :filter-path="'tecnologyTransfers/filterIndustrialProperty'"
              :export-path="'tecnologyTransfers/exportIndustrialProperty'"
            >
              <!-- :export-path="'tecnologyTransfers/exportIndustrialProperty'" -->
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.dashboard', params: { id: field.user.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div
                        v-if="hasPermission('update industrial properties')"
                        class="dropdown-divider"
                      />
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View industrial property
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasAdminPermission('view industrial properties') || (loggedUser.id === field.user.id && hasPermission('update industrial properties'))"
                        :href="$router.resolve({name: 'researcher.industrial-property.edit', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete industrial properties')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view industrial properties')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Title</label>
                  <input
                    v-model="filters.title"
                    type="text"
                    class="form-control"
                    @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Patent number</label>
                  <input
                    v-model="filters.patent_number"
                    type="text"
                    class="form-control"
                    @change="saveFilter({ customLabel: true, name: `Patent number: ${$event.target.value}`, value: `${$event.target.value}` }, 'patent_number')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Status</label>
                  <v-select
                    v-model="filters.status"
                    label="status"
                    :options="statuses"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Host</label>
                  <v-select
                    v-model="filters.host"
                    label="name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Host: ${$event ? $event.name : ''}`, value: $event }, 'host')"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Type</label>
                  <v-select
                    v-model="filters.type"
                    label="name"
                    :options="types"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Type: ${$event ? $event.name : ''}`, value: $event }, 'type')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Filing date (From year)</label>
                  <date-picker
                    v-model="filters['filing_date_from']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Filing date from year: ${$event}`, value: `${$event}` }, 'filing_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Filing date (To year)</label>
                  <date-picker
                    v-model="filters['filing_date_to']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Filing date to year: ${$event}`, value: `${$event}` }, 'filing_date_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Contract date (From year)</label>
                  <date-picker
                    v-model="filters['contract_date_from']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Contract date from year: ${$event}`, value: `${$event}` }, 'contract_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Contract date (To year)</label>
                  <date-picker
                    v-model="filters['contract_date_to']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Contract date to year: ${$event}`, value: `${$event}` }, 'contract_date_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Active</label>
                  <select
                    id=""
                    v-model="filters.active"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >ICREA active</label>
                  <select
                    id=""
                    v-model="filters.researcher_active"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'ICREA active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'researcher_active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Researcher<br>
                      <strong>{{ item.user.first_name }} {{ item.user.last_name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Title<br>
                      <strong>{{ item.title }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Host<br>
                      <strong>{{ item.current_host }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Patent number<br>
                      <strong>{{ item.patent_application_number }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Status<br>
                      <strong>{{ item.status ? item.status.status : '-' }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Type<br>
                      <strong>{{ item.type ? item.type.name : '-' }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Active<br>
                      <strong
                        v-if="!item.publish"
                        class="badge badge-light-danger"
                      >No</strong>
                      <strong
                        v-if="item.publish"
                        class="badge badge-light-success"
                      >Yes</strong>
                    </p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Filing date<br>
                      <strong>{{ item.filing_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Contract date<br>
                      <strong>{{ item.contract_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Comunication date to icrea<br>
                      <strong>{{ item.communication_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Licensed date<br>
                      <strong>{{ item.licensed_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Sold date<br>
                      <strong>{{ item.sold_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Abandoned date<br>
                      <strong>{{ item.abandoned_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Publication date<br>
                      <strong>{{ item.publication_date }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Inventors</p>
                    <ul>
                      <li
                        v-for="inventor in item.inventors"
                        :key="inventor.id"
                      >
                        <template v-if="inventor.user_id">
                          <a
                            v-if="hasAdminPermission('view industrial properties')"
                            :href="$router.resolve({name: 'researcher.dashboard', params: { id: inventor.user_id }, query: { actAs: $route.query.actAs }}).href"
                            target="_blank"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="view profile"
                          >
                            {{ inventor.name }} {{ inventor.host_code ? `- (${inventor.host_code})` : '' }}
                          </a>
                          <a
                            v-else
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="view profile"
                          >
                            {{ inventor.name }} {{ inventor.host_code ? `- (${inventor.host_code})` : '' }}
                          </a>
                          <span class="badge bg-light-secondary">ICREA</span>
                        </template>
                        <template v-else>
                          {{ inventor.name }} {{ inventor.host_code ? `- (${inventor.host_code})` : '' }}
                        </template>
                      </li>
                    </ul>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <h4>Abstract / Summary</h4>
                    <p v-html="item.summary" />
                  </div>
                  <hr>
                  <div class="mb-1">
                    <h4>Documents</h4>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>File name</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="file in item.files"
                          :key="file.id"
                        >
                          <td>{{ file.name }}</td>
                          <td class="text-end">
                            <a
                              :href="file.url"
                              target="_blank"
                            ><i data-feather="download" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  v-if="hasPermission('update industrial properties')"
                  class="offcanvas-footer mt-auto"
                >
                  <a
                    v-if="hasAdminPermission('view industrial properties') || loggedUser.id === item.user.id"
                    title="edit this industrial property"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="$router.push({name: 'researcher.industrial-property.edit', params: { id: item.id }})"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'Host',
          checked: true,
          order: 2,
        },
        {
          name: 'Type',
          checked: true,
          order: 3,
        },
        {
          name: 'Filing date',
          checked: true,
          order: 4,
        },
        {
          name: 'Title',
          checked: true,
          order: 5,
        },
        {
          name: 'Patent number',
          checked: true,
          order: 6,
        },
        {
          name: 'Contract date',
          checked: false,
          order: 7,
        },
        {
          name: 'Licensed date',
          checked: false,
          order: 8,
        },
        {
          name: 'Sold date',
          checked: false,
          order: 9,
        },
        {
          name: 'Abandoned date',
          checked: false,
          order: 10,
        },
        {
          name: 'Publication date',
          checked: false,
          order: 11,
        },
        {
          name: 'Status',
          checked: true,
          order: 12,
        },
        {
          name: 'Published',
          checked: true,
          order: 13,
        },
      ],
      sortMapping: {
        Researcher: 'users_table',
        Title: 'title',
        Host: 'current_host',
        'Patent number': 'patent_application_number',
        'Filing date': 'filing_date',
        'Contract date': 'contract_date',
        'Licensed date': 'licensed_date',
        'Sold date': 'sold_date',
        'Abandoned date': 'abandoned_date',
        'Publication date': 'publication_date',
        Status: 'status.status_table',
        Type: 'type.name',
        Published: 'publish',
      },
      title: 'Industrial property',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      properties: 'tecnologyTransfers/industrialProperties',
      propertiesTotal: 'tecnologyTransfers/industrialPropertiesTotal',
      users: 'users/fakeUsers',
      statuses: 'status/statuses',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      types: 'industrialPropertyParentTypes/items',
      hosts: 'institutions/institutions',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'industrial-property')
    if (this.$route.query.user_id) {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/show/${this.$route.query.user_id}`)
      const user = data.data
      if (user) {
        this.saveFilter({ customLabel: true, name: `Researcher: ${user.name}`, value: { name: user.name, id: this.$route.query.user_id } }, 'researcher')
      }
    }
    await this.$store.dispatch('tecnologyTransfers/filterIndustrialProperty', this.filters)
    await this.$store.dispatch('industrialPropertyParentTypes/filter', this.$store.getters['filters/filters'])
    this.$store.dispatch('status/filterStatus', 'Patent')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'industrial-property',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      // s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'tecnologyTransfers/fetchIndustrialProperties', url: `${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
